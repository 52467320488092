import { default as React, useState } from "react"
import Seo from "../components/seo"
import { Box, Container, OutlinedInput, Hidden, IconButton, InputAdornment, Typography } from "@mui/material"
import { Search } from '@mui/icons-material';
import { navigate } from 'gatsby'

export default function Index() {

  const [value, setValue] = useState("")

  const handleChange = (e) => {
    const newValue = e.target.value
    setValue(newValue)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // TODO: Find a better way of doing this
    const path = `/dessert-recipes?dev_FIND_COUVERTURE%5Bquery%5D=${value}`

    navigate(path)
  }

  return (
    <>
      <Seo title="Quenellegant | Quality Dessert Recipes" />
      <Container maxWidth="md">
        <Box>
          <Hidden smDown>
            <Typography variant="h4" component="h1" mt={24}>
              Find Quality Dessert Recipes from Top Pastry Chefs
            </Typography>
            <Typography variant="h5" component="h2" mb={8} mt={4}>
              Unlock the secrets of professional pastry chefs, patissiers and chocolatiers and elevate your dessert game to new heights.
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Typography variant="h5" component="h1" mb={6} mt={4}>
              Find Quality Dessert Recipes from Top Pastry Chefs, Patissiers and Chocolatiers
            </Typography>
          </Hidden>
          <form onSubmit={handleSubmit}>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Search for Desserts, Flavours or Ingredients"
              aria-label="Search"
              value={value}
              onChange={handleChange}
              sx={{
                "& input::placeholder": {
                  fontSize: "14px"
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    onClick={handleSubmit}
                    edge="end"
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
            />
          </form>
          <Typography variant="h6" component="h3" mb={2} mt={8}>
            What is Quenellegant.com?
          </Typography>
          <Typography variant="body1" mb={8}>
            Our search engine is designed to help you delve into the realm of culinary excellence, revealing the closely guarded recipes and techniques of the masters. With our curated collection of high-quality desserts, you can access the very same recipes used by top chefs and renowned dessert websites.
          </Typography>
        </Box>
      </Container>
    </>
  )
}
